<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>
      <a-form-model-item label='标题' prop='title' >
        <a-input v-model='form.title' placeholder='请输入标题' :maxlength='10' />
      </a-form-model-item>
<!--      <a-form-model-item label='封面' prop='videoPic'>-->
<!--        <video :src='form.videoUrl'-->
<!--               style='height:104px;max-width:300px' />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label='视频' prop='videoUrl' >
        <div v-if="form.videoPic" style="display: inline-block;position:relative;">
          <img v-if="form.videoPic != null" :src='form.videoPic' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay()" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
        </div>
        <a-upload v-if="!form.videoPic"  listType='picture-card'
                    class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => fileBeforeUpload("videoUrl",info)'
                    :customRequest='(info) => fileUploadRequest("videoUrl",info)' v-model="form.videoUrl">
          <img v-if="form.videoUrl != null" :src='form.videoUrl' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.videoPic">
          <a-button @click="restVideo">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='排序' prop='sort' >
        <a-input-number v-model='form.sort' placeholder='请输入排序' :max='999' :min='1' />
      </a-form-model-item>
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <VideoPlay ref="videoPlay"></VideoPlay>
  </a-drawer>
</template>

<script>
import { getHelp, addHelp, updateHelp } from '@/api/user/help'
import { Log } from '@/components/crud/Log'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
import VideoPlay from '@/components/VideoPlay'
export default {
  name: 'CreateForm',
  props: {}
  ,
  components: {
    VideoPlay,
  }
  ,
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      loading:false,
      // 表单参数
      form: {
        id: null,

        type: null,

        title: null,

        videoPic: null,

        videoUrl: null,
        videoTime: null,
        sort: null,

        createTime: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {}
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        title: null,
        videoPic: null,
        videoUrl: null,
        videoTime: null,
        sort: null,
        createTime: null
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getHelp({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    }
    ,
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateHelp(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addHelp(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      console.log('uploadfileInfo====前', fileInfo);
      if (!fileInfo.type.includes('video')) {
        this.$message.warning('请上传视频');
        return false;
      }
      if(fileInfo.size > 50*1024*1000){
        this.$message.warning("视频大小不能超过50M");
        return;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {
      console.info('uploadfileInfo上传', fileInfo);
      // debugger
      let that = this;
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          console.log('upload', res);
          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url;
          this.findVideoPoster(fileInfo.file);
        },
      });
    },
    findVideoPoster(file) {
      let self = this
      return new Promise(function(resolve) {
        let base64URL = ''
        let video = document.createElement('video')
        video.setAttribute('crossOrigin', 'anonymous') //处理跨域
        video.setAttribute('src', URL.createObjectURL(file))
        video.currentTime = 1
        let duration;
        video.addEventListener('loadeddata', function() {
          let canvas = document.createElement('canvas')
          //使用视频的宽高作为canvas、预览图的宽高
          let width = video.videoWidth
          let height = video.videoHeight
          canvas.width = width
          canvas.height = height
          debugger
          self.form.videoTime = video.duration
          canvas.getContext('2d').drawImage(video, 0, 0, width, height) //绘制canvas
          base64URL = canvas.toDataURL('image/jpeg') //转换为base64，图片格式默认为png，这里修改为jpeg
          let fileName = String(new Date().getTime()) + parseInt(Math.random() * 100000) + '.jpeg'
          const imgfile = self.data64toFile(base64URL)
          console.log("imgfile")
          console.log(imgfile)
          new UploadFileToOSS([imgfile], {
            finally: (res) => {
              console.info('upload图片', res);
              this.loading = false;
              self.form.videoPic = res[0].url;
            },
          });
        })
      })
    },
    data64toFile(base64URL) {
      const arr = base64URL.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    videoPlay: function () {
      let data = {
        coverImage: this.form.videoPic,
        videoUrl: this.form.videoUrl
      };
      this.$refs.videoPlay.show(data);
    },
    // 视频鼠标悬浮时的样式
    enter: function ($event) {
      $event.currentTarget.className = "enter";
    },
    // 视频鼠标离开时的样式
    leave: function ($event) {
      $event.currentTarget.className = "leave";
    },
    restVideo() {
      this.form.videoPic = null;
      this.form.videoUrl = null;
      this.form.videoTime = null;
      this.loading = false
    },
  }
}
</script>
