import request from '@/utils/request'


// 查询新手指南列表
export function listHelp(query) {
    return request({
        url: '/user/user-help/list',
        method: 'get',
        params: query
    })
}

// 查询新手指南分页
export function pageHelp(query) {
    return request({
        url: '/user/user-help/page',
        method: 'get',
        params: query
    })
}

// 查询新手指南详细
export function getHelp(data) {
    return request({
        url: '/user/user-help/detail',
        method: 'get',
        params: data
    })
}

// 新增新手指南
export function addHelp(data) {
    return request({
        url: '/user/user-help/add',
        method: 'post',
        data: data
    })
}

// 修改新手指南
export function updateHelp(data) {
    return request({
        url: '/user/user-help/edit',
        method: 'post',
        data: data
    })
}

// 删除新手指南
export function delHelp(data) {
    return request({
        url: '/user/user-help/delete',
        method: 'post',
        data: data
    })
}
